* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 1.4;
    color: #333;
    transition: all 300ms ease;
    position: relative; }
