
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

* {
	font-family: 'Open Sans', sans-serif;
	font-size: 13px; }


a {
	color: $base-font-color;
	&:hover {
		color: $main-color; } }

h1, h2 {
	font-size: 3em;
	margin-bottom: 30px; }
h3 {
	font-size: 1em;
	margin-bottom: 10px; }
h4 {
	font-size: 1.8em;
	margin-bottom: 30px; }
h5 {
	font-size: 1.4em;
	margin-bottom: 30px; }



