.glasses {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 210px;
  max-height: 100%;
  overflow-y: scroll;
  #noresults {
    display: none;
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    background: white;
    margin: 30vh 15px 15px 15px;
    h3 {
      color: #ccc; } }

  .brille {
    // width: 31%
    // flex: 1 0 0
    height: 0;
    min-width: 30%;
    max-width: 50%;
    padding-bottom: 23%;
    border: 1px solid $light-grey;
    margin: 1%;
    background: white;
    cursor: pointer;
    align-self: flex-start;
    display: none;
    overflow: hidden;
    &.active {
      display: block; }

    .thumb {
      max-width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%); } }

  .detail {
    position: sticky;
    padding: 0 30px;
    overflow: hidden;
    background: white;
    bottom: 20px;
    z-index: 99999999999999;
    margin: 20px;
    display: none;
    background: white;
    width: 100%;
    table {
      position: absolute;
      bottom: 30px;
      left: 30px; }

    td {
      padding: 0 10px 10px 10px;
      text-align: left; }
    &.active {
      border: 1px solid $main-color;
      height: 600px;
      max-height: 60vw;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        display: inline-block;
        cursor: pointer;
        z-index: 999; } }

    img {
      width: 100%;
      max-width: 100%;
      position: absolute;
      top: 0; } }

  @media (max-width: 600px) {
    padding-left: 0px;
    display: flex;
    text-align: center;
    align-items: flex-start;
    .brille {
      flex: 1 0 0;
      min-width: 150px;
      max-width: 50%;
      height: 150px;
      border: 1px solid $light-grey;
      margin: 5px 0 0 5px;
      background: white;
      cursor: pointer; }
    .detail {
      position: fixed;
      left: 0px;
      top: 50px;
      right: 0px;
      bottom: auto;
      width: auto;
      max-height: 100vw!important; } } }




#filterMenuMobile {
  display: none;
  @media (max-width: 600px) {
    display: block;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $light-grey;
    select {
      margin: 5px;
      font-size: 0.925em;
      border: none;
      border-radius: none;
      outline: none;
      padding: 5px;
      background: white; } } }


#filterMenu {
  display: block;
  width: 200px;
  background: $light-grey;
  padding: 20px 15px;
  height: 100%;
  z-index: 9999999999999;
  position: absolute;
  left: 0;
  overflow-y: auto;
  h3 {
    font-size: 1.0em;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-weight: 100;
    padding-bottom: 5px;
    color: $base-font-color;
    border-bottom: 1px solid $main-color; }
  a {
    font-size: 1em;
    text-align: right;
    display: block;
    padding: 3px 0;
    color: $grey;
    position: relative;
    cursor: pointer;
    &:before {
      content: "\203A";
      position: relative;
      color: $main-color;
      left: -15px;
      font-size: 1.1em;
      opacity: 0;
      transition: all 300ms ease; }
    &.active {
      color: $main-color;
      &:before {
        opacity: 1;
        content: "\203A";
        position: relative;
        color: $main-color;
        left: -5px;
        font-size: 1.1em; } } }
  @media (max-width: 600px) {
    display: none; } }


