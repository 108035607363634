body, html {
  background: white;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  height: 800px;
  overflow: hidden; }
.container {
    max-width: 1300px;
    margin: 0 auto;
    display: block;
    height: 100%;
    border: 1px solid $light-grey;
    overflow: hidden; }




